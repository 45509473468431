









import Vue from 'vue';

export default Vue.extend({
  data() {
    return {};
  },
  created() {},
  errorCaptured(err, vm, info) {
    if (process.client) {
      const fundebug = require('fundebug-javascript');
      fundebug.notify('ErrorCaptured', info, {
        metaData: { err, vm, info },
      });
    } else {
      console.error('ErrorCaptured', err);
    }
    return false;
  },
  methods: {},
  head() {
    const meta = [
      { name: 'og:image', content: 'https://www.jufair.com/logo.png' },
    ];
    let currentPageUrlStr = this.$route.fullPath || '';

    const head: Record<string, any> = {
      link: [
        {
          rel: 'canonical',
          href: `https://www.jufair.com${currentPageUrlStr}`,
        },
      ],
    };
    if (!/exhibition\/\d+\.html/.test(this.$route.path)) {
      head.meta = meta;
    }
    return head;
  },
});
