








import Vue from 'vue';
export default Vue.extend({
  errorCaptured(err, vm, info) {
    if (process.client) {
      const fundebug = require('fundebug-javascript');
      fundebug.notify('ErrorCaptured', info, {
        metaData: { err, vm, info },
      });
    } else {
      console.error('ErrorCaptured', err);
    }
    return false;
  },
});
