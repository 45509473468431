



























































import Vue from 'vue';
import { $cookies } from '~/util/api';
import Join from '~/components/Join/index.vue';
// import Sidebar from '~/components/Sidebar/index.vue';

export default Vue.extend({
  components: {
    Join,
    // Sidebar,
  },
  data() {
    return {
      visibleLinks: false,
      showFooter: true,
      joinVisible: false,
      isSdkReady: false, // 客戶sdk初始化判断
      w: 0,
      url: '',
    };
  },
  mounted() {
    // this.init();
    const router = this.$route.path;
    this.url = router.substr(1, router.indexOf('/', 1) - 1);
  },
  created() {
    const { path, name } = this.$route;
    // 首页和展会列表页显示友情链接
    if (path === '/' || /^\/exhibition((-\d+){7}|)\/$/.test(path)) {
      this.visibleLinks = true;
    }

    if (name === '选择支付方式') {
      this.showFooter = false;
    }
  },
  methods: {
    init() {
      window.ysf('onready', () => {
        this.isSdkReady = true;
      });
    },
    // isLogin
    shouldLogin() {
      const access_token = $cookies.get('access_token');
      // 获取不到token为未登录, 前往登录页
      if (!access_token) {
        this.$login.show();
        // this.$router.push(`/login?redirect=${encodeURIComponent(this.$route.fullPath)}`);
      }
      return !!access_token;
    },
    openkefu() {
      if (this.isSdkReady) {
        window.ysf('open');
      }
    },
    openWindow(path: string) {
      if (this.shouldLogin()) {
        window.open(path, '_blank');
      }
    },
    sidebarChange(e: any) {
      this.w = e;
    },
  },
  errorCaptured(err, vm, info) {
    if (process.client) {
      const fundebug = require('fundebug-javascript');
      fundebug.notify('ErrorCaptured', info, {
        metaData: { err, vm, info },
      });
    } else {
      console.error('ErrorCaptured', err);
    }
    return false;
  },
  head() {
    const meta = [
      {
        name: 'og:image',
        content: 'https://www.jufair.com/logo.png',
      },
    ];
    let currentPageUrlStr = this.$route.fullPath;
    if (this.$route.fullPath.includes('/seo')) {
      currentPageUrlStr = this.$route.fullPath.split('/seo')[1];
    }

    const link = [
      {
        rel: 'canonical',
        href: `https://www.jufair.com${currentPageUrlStr}`,
      },
    ];
    const head: Record<string, any> = {};
    if (
      !/exhibition\/\d+\.html/.test(this.$route.path) &&
      !/information\/\d+\.html/.test(this.$route.path) &&
      !/information/.test(this.$route.path)
    ) {
      head.meta = meta;
    }
    if (
      !/exhibition(-\d+){7}/.test(this.$route.path) &&
      !/information(-\d+){3}/.test(this.$route.path) &&
      !/information/.test(this.$route.path)
    ) {
      head.link = link;
    }

    return head;
  },
});
