







import Vue from 'vue';

export default Vue.extend({
  props: ['error'],
  layout: 'error', // you can set a custom layout for the error page
  created() {
    // if (this.error.statusCode === 504) {
    //   this.$router.push({
    //     name: '登录'
    //   })
    // }
  },
  mounted() {
    const fundebug = require('fundebug-javascript');
    fundebug.notify('ErrorCaptured', this.error.message, {
      metaData: this.error,
    });
  },
  head: {
    title: '聚展网',
    meta: [
      {
        name: 'robots',
        content: 'noindex, nofollow',
      },
    ],
  },
});
